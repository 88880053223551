* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  background: $dark-gray;
  font-size: 1.8rem;
}

.fixed {
  overflow: hidden;
}

a {
  color: $red;
  text-decoration: none;
}

.content img {
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
}

.container-menu {
  background: $black;
}

header {
  padding: 0 2.5rem;
  background-color: #1f2222;
}

nav {
  position: relative;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  z-index: 100;
  margin: 0 auto;
  max-width: 1200px;
  background: $black;
  border-bottom: 30px $red solid;
}

nav .logo {
  flex: 1 1 225px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .logo a {
  width: 100%;
  min-height: 78px;
  position: relative;
}

nav .logo #menu-button {
  width: auto;
  min-height: 0;
  font: bold 2.4rem/4rem "alternate-gothic-no-2-d", sans-serif;
}

nav .logo .small-size-logo {
  display: none;
}

nav .logo img.full-size-logo {
  display: block;
  position: absolute;
  top: 150px;
  margin: -150px 0 0 -405px;
}

nav ul {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
}

nav ul li a {
  display: block;
  position: relative;
  z-index: 100;
  background: $black;
  padding: 0 3rem;
  transition: 0.5s all ease-in-out;
}

nav ul li a.active {
  background: $red;
  border-radius: 5px 5px 0 0;
}

nav ul li a {
  color: #fff;
  font: bold 2.4rem/7.8rem "alternate-gothic-no-2-d", sans-serif;
  text-transform: uppercase;
}

nav ul.pages li ul {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 0;
  width: 280px;
  transform: translateY(-100%);
  transform-origin: top;
  background: $red;
  border-radius: 0 0 5px 5px;
  transition: 0.5s all ease-in-out;
}

nav ul.pages li ul.active {
  opacity: 1;
  transform: translateY(0%);
  padding: 3rem 0;
}

nav ul.pages li ul li {
  width: 280px;
}

nav ul.pages li ul li a {
  background: $red;
  font: bold 2rem/1.5 "alternate-gothic-no-2-d", sans-serif;
  letter-spacing: 1px;
}

.border-box {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 50%;
  height: 108px;
  background: $black;
  border-bottom: 30px $red solid;
}

.narrow .intro .container,
.narrow .content .container {
  max-width: 600px;
}

.content {
  padding: 0 2.5rem;
  background: #fff;
  padding-bottom: 18rem;
  overflow: auto;
}

.content > .container > p:first-child {
  margin: 6rem 0 0;
}

.p-t-6 {
  padding-top: 6rem;
}

.p-t-12 {
  padding-top: 12rem;
}

.p-b-6 {
  padding-bottom: 6rem;
}

.p-b-12 {
  padding-bottom: 12rem;
}

.halves {
  display: flex;
  justify-content: space-between;
}

.halves div {
  flex: 0 1 calc(50% - 40px);
}

.thirds {
  margin: 40px 0 0;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.thirds div {
  flex: 0 1 calc(33% - 10px);
  margin: 5px;
}

.thirds.border div {
  padding: 40px;
  background: #eee;
  border-radius: 5px;
}

.thirds.border div h2 {
  margin: 0 0 20px;
}

.quarters {
  margin: 40px 0 0;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.quarters div {
  flex: 0 1 calc(25% - 10px);
  margin: 5px;
}

.simple-border {
  display: flex;
  margin: 3rem 0 0;
  padding: 40px 20px;
  border: 1px solid #eee;
  border-radius: 5px;
  align-items: center;
}

.simple-border h2 {
  margin: 0;
}

.simple-border > * {
  flex: 0 0 auto;
  margin: 0 20px;
}

.simple-border > a {
  flex-grow: 1;
}

.vertical-center {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.vertical-center span {
  margin: 40px 0;
  text-align: center;
}

.button {
  line-height: calc(5rem - 2px);
}

footer {
  padding: 0 2.5rem;
  background: $dark-gray;
}

footer .container {
  padding: 6rem 0 2rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

footer .container > * {
  flex: 0 1 auto;
}

footer ul {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
}

footer ul.pages a {
  padding-right: 3rem;
  color: #737781;
  font: 400 2.4rem/4rem "alternate-gothic-no-2-d", sans-serif;
}

footer ul.social a {
  padding-left: 2rem;
  color: $black;
  font: 400 1.4rem/4rem Helvetica, sans-serif;
  opacity: 0.6;
}

footer ul.social a:hover {
  opacity: 1;
}

footer ul.social a img {
  max-height: 40px;
}

footer ul.legal a {
  padding-right: 3rem;
  color: $black;
  font: 400 1.4rem/4rem Helvetica, sans-serif;
  opacity: 0.6;
}

footer ul.pages li:last-child a,
footer ul.legal li:last-child a {
  padding: 0;
}

.container.container-copyright {
  padding: 2rem 0 6rem;
  border-top: 1px solid #bfbfbf;
  color: $black;
}

.copyright {
  padding: 1rem 0;
  font: 400 1.4rem/2rem Helvetica, sans-serif;
  opacity: 0.6;
}

.button {
  display: inline-block;
  padding: 0 3rem;
  background: $red;
  border: 1px solid $red;
  border-radius: 5px;
  color: #fff;
  font: bold 1.4rem/6rem "SF Pro Text", sans-serif;
  text-align: center;
  white-space: nowrap;
}

.button.clear {
  margin: 1rem 3rem;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 3px solid #{$red}33;
  border-radius: 0;
  color: $red;
  line-height: calc(3rem - 1px);
  letter-spacing: 0;
  vertical-align: middle;
}

.button.outline {
  background: none;
  color: $red;
}

.button.small {
  padding: 0 1.5rem;
  background: #{$red}10;
  border: none;
  color: $red;
  line-height: 3rem;
  text-transform: none;
}

.small-only {
  display: none;
}

@media (max-width: 900px) {
  header {
    padding: 0;
  }

  nav {
    padding: 2.5rem;
    border: none;
  }

  nav.active {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2.5rem;
    background: $black;
  }

  nav.active .call-us {
    flex: 0 0 auto;
    width: 100%;
  }

  nav .logo {
    flex: 1 1 auto;
  }

  nav .logo .full-size-logo {
    display: none;
  }

  nav .logo .small-size-logo {
    display: block;
  }

  nav .logo img {
    height: 78px;
  }

  nav ul {
    display: none;
  }

  nav.active .logo {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 78px;
  }

  nav.active ul {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    padding: 5rem 0;
  }

  nav.active ul li {
    padding: 0 !important;
  }

  nav.active ul.pages {
    justify-content: flex-start;
    width: 100%;
  }

  nav.active ul.pages li a {
    font-size: 3rem;
  }

  nav.active ul.pages li ul {
    position: relative;
    opacity: 1;
    transform: translateY(0);
    padding: 1rem 0 5rem 0;
    width: 100%;
    background: none;
    border-top: 1px solid $red;
  }

  nav.active ul.pages li ul li a {
    background: none;
    font-size: 2rem;
    text-transform: none;
  }

  .border-box {
    display: none;
  }

  .halves,
  .thirds,
  .quarters,
  .simple-border {
    flex-flow: wrap;
  }

  .halves div,
  .thirds div,
  .quarters div,
  .simple-border > * {
    flex: 1 1 100%;
  }

  .simple-border {
    padding: 20px;
  }

  .simple-border img {
    margin: 0 0 20px;
    width: 64px;
    height: auto;
  }

  footer ul {
    flex: 1 1 100%;
    margin: 0 0 1rem;
  }

  footer .container {
    padding: 2rem 0 2rem;
  }

  .container.container-copyright {
    padding: 2rem 0 2rem;
    border-top: 1px solid #bfbfbf;
    color: $black;
  }

  .copyright {
    order: 2;
  }

  footer ul.social {
    display: flex;
    justify-content: flex-end;
    order: 1;
    width: 100%;
  }

  footer ul.social li {
    flex: 0 1 auto;
  }

  .small-only {
    display: inline-block;
  }
}
