form {
  
}

.content form {
  max-width: 700px;
}

label {
  margin: 0;
  color: $red;
  font: bold 1.4rem/5rem "SF Pro Text", sans-serif;
}

input, textarea {
  padding: 0 1rem;
  margin: 0 0 2.5rem;
  height: 60px;
  width: 100%;
  font: 300 1.8rem/calc(6rem - 2px) "SF Pro Text", sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 120px;
}

input[type=submit] {
  display: block;
  margin: 5rem 0 0;
  padding: 0 6rem;
  background: $red;
  border: 1px solid $red;
  color: #fff;
  font: bold 1.4rem/6rem "SF Pro Text", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}

.single-line {
  display: flex;
  flex-flow: wrap;
}

.single-line input[type=email] {
  flex: 1 0 auto;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
}

.single-line input[type=submit] {
  border-radius: 0 5px 5px 0;
}

@media (max-width: 500px) {
  .single-line input[type=email],
  .single-line input[type=submit] {
    flex: 1 0 100%;
    border-radius: 5px 5px 0 0;
  }

  .single-line input[type=submit] {
    border-radius: 0 0 5px 5px;
  }
}

p#login-error-message {
  color: $red;
  font-weight: bold;
  display: none;
}