body {
  color: #1f2222;
  font: 1.8rem/3rem Helvetica, sans-serif;
}

h1 {
  margin: 0;
  color: #fff;
  font: 6rem/5rem "alternate-gothic-no-2-d", sans-serif;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 1);
}

h2 {
  position: relative;
  margin: 6rem 0 3rem;
  color: $red;
  font: 3.6rem/4rem "alternate-gothic-no-2-d", sans-serif;
}

h2.marker {
  padding: 6rem 0 0 0;
}

h2.marker:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: '';
  background: $red;
  width: 150px;
  height: 10px;
}

h3 {
  margin: 0 0 1rem;
  color: $red;
  font: 2.4rem/3rem "alternate-gothic-no-2-d", sans-serif;
}

p {
  margin: 0 0 2.5rem;
}

p.large {
  margin: 0 0 5rem;
  color: #9599a3;
  font: 300 1.8rem/2.8rem "alternate-gothic-no-2-d", sans-serif;
}

p.small {
  margin: 1rem 0;
  font-size: 1.4rem;
}

@media (max-width: 500px) {
  h1 {
    font: bold 3.6rem/4rem "alternate-gothic-no-2-d", sans-serif;
  }

  h2 {
    font: bold 3rem/1.25 "alternate-gothic-no-2-d", sans-serif;
  }

  h3 {
    font: bold 2.4rem/1.25 "alternate-gothic-no-2-d", sans-serif;
  }
}