.glide__slide div {
  margin: 0 auto;
  padding: 50px 100px;
  max-width: 500px;
  background: #eee;
  border-radius: 5px;
  font: 1.4rem/2rem Helvetica, sans-serif;
}

.glide__slide div.full {
  background: transparent;
  padding: 0;
}

.glide__slides.full img,
.glide__slide div.full img {
  max-height: none;
}

.glide__slide {
  text-align: center;
}

.glide__slide h3,
.glide__slide p {
  text-align: left;
}

.glide__slide img {
  max-height: 200px;
  max-width: 100%;
  display: inline-block;
}

@media (max-width: 900px) {
  .glide {
    max-width: calc(100vw - 50px) !important;
  }
}