.intro {
  margin-top: -45px;
  padding: 10rem 2.5rem 5rem;
  background: $darker-gray;
  text-align: right;
}

.slideshow {
  overflow: hidden;
  display: flex;
  margin-top: -45px;
  background: $darker-gray;
}

.slide {
  flex: 0 0 100%;
  padding: 35rem 10rem 5rem;
  background-size: cover;
}

.slide-01 { background: $darker-gray url('../images/banners/banner-01.jpg') 50% 50% / cover no-repeat; }
.slide-02 { background: $darker-gray url('../images/banners/banner-02.jpg') 50% 50% / cover no-repeat; }
.slide-03 { background: $darker-gray url('../images/banners/banner-03.jpg') 50% 50% / cover no-repeat; }
.slide-04 { background: $darker-gray url('../images/banners/banner-04.jpg') 50% 50% / cover no-repeat; }

.slide {
  animation: 20s infinite slidein;
}

@keyframes slidein {
  from, 20%, to {
    transform: translateX(0%);
  }

  25%, 45% {
    transform: translateX(-100%); 
  }

  50%, 70% {
    transform: translateX(-200%);
  }

  75%, 95% {
    transform: translateX(-300%);
  }
}

.slide h1 {
  margin: 20rem 0 0;
  float: right;
  max-width: 1200px;
  font-size: 12rem;
  font-style: italic;
  line-height: 1;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 1), 0px 3px 15px rgba(0, 0, 0, 1);
}

.products-bar {
  padding-bottom: 2rem;
  background: #fff;
  border-bottom: 1px solid $dark-gray;
}

.products-bar .header {
  padding: 0 2.5rem;
  background: $red;
  overflow: auto;
}

.products-bar .header h2 {
  margin: 6rem 0 2rem;
  color: $black;
  line-height: 1;
}

.products-sprites {
  position: relative;
  top: -10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 0 1rem;
}

// .products-sprites li {
//   flex: 0 0 145px;
//   font: 2rem/2rem "alternate-gothic-no-2-d", sans-serif;
//   text-align: center;

//   a {
//     color: $black;
//   }
// }

// .products-sprite {
//   max-width: 150px;
//   min-height: 100px;
//   background: url('../images/products-sprite.png') no-repeat;
//   border-radius: 5px 5px 0 0;
// }

.products-sprites li {
  flex: 0 0 auto;
  font: 2.2rem/2rem "alternate-gothic-no-2-d", sans-serif;
  text-align: center;

  a {
    color: $black;
  }
}

.products-sprite {
  max-width: 150px;
  height: 10px;
  margin: 0 0 4rem;
}

@for $i from 1 through 8 {
  .products-sprites li:nth-child(#{$i}) .products-sprite {
    background-position-y: -(100px * ($i - 1));
  }
}

.product-parts h3 {
  padding: 6rem 0 0 0;
}

.product-parts ul {
  margin: 3rem 0 0;
  padding: 3rem 0 0 0;
}

.product-parts ul li {
  list-style: circle;
  list-style-position: inside;
  padding: 1.5rem 1rem;
  border-top: 1px solid $dark-gray;
  line-height: 2.4rem;
}

.text {
  max-width: 800px;
}

@media (max-width: 1450px) {
  .intro {
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .intro {
    margin-top: -45px;
    padding: 10rem 2.5rem 5rem;
    background: $darker-gray;
    text-align: left;
  }

  .slide {
    padding: 5rem 5rem 15rem;
  }

  .slide h1 {
    float: none;
    font-size: 8rem;
  }

  .products-sprites {
    padding: 0 2.5rem 1rem;
  }

  .products-sprites li {
    flex: 1 1 145px;
  }
}

@media (max-width: 500px) {
  .products-bar {
    display: none;
  }

  .slide {
    padding: 5rem 5rem 20rem;
  }

  .slide h1 {
    float: none;
    font-size: 4rem;
  }
}